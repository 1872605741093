<template>
    <div ref="chart" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props:['chartData'],
    mounted() {
        this.chartData.toolbox.show = false;
        let chartHandle = echarts.init(this.$refs.chart);
        chartHandle.setOption(this.chartData);
    }
}
</script>

<style lang="scss" scoped>
.chart {
    height:400px;
    margin-right:100px;
}
</style>