<template>
    <a-layout>
        <a-layout-sider>

            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.key" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">曲线估计分析结果</h1>
            

            <div v-if="curvefitBOLst">
                <div class="result-area" v-for="(value,index) in curvefitBOLst" :key="index" :id="'nav'+index">
                    <divider-title :name="value.modelName" />
                    <div class="area-level2">
                        <h3 class="h3">变量说明：</h3>
                        <div v-for="(item,key) in value.varsProduce" :key="key">
                            【{{item.typeName}}】{{item.key}}:{{item.value}}
                        </div>
                    </div>
                    <div class="area-level2">
                        <h3 class="h3">方程：</h3>
                        <div class="">{{value.formula}}</div>
                    </div>

                    <div class="area-level2">
                        <h3 class="h3">模型汇总：</h3>
                        <div class="ant-table-wrapper">
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                        <div class="ant-table-content">
                                            <div class="ant-table-body">
                                                <table class="">
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th style="width:150px">R</th>
                                                            <th style="width:150px">R<sup>2</sup></th>
                                                            <th style="width:150px">调整R<sup>2</sup></th>
                                                            <th style="width:150px">估计标准误差</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>{{toFixedNum(value.modelSummary.r)}}</td>
                                                            <td>{{toFixedNum(value.modelSummary.r2)}}</td>
                                                            <td>{{toFixedNum(value.modelSummary.adjR2)}}</td>
                                                            <td>{{toFixedNum(value.modelSummary.stdErrorOfEst)}}</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="area-level2">
                        <h3 class="h3">方差分析表：</h3>
                        <div class="ant-table-wrapper">
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                        <div class="ant-table-content">
                                            <div class="ant-table-body">
                                                <table class="">
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th style="width:150px"></th>
                                                            <th style="width:150px">平方和</th>
                                                            <th style="width:150px">df</th>
                                                            <th style="width:150px">均方</th>
                                                            <th style="width:150px">F</th>
                                                            <th style="width:150px">Sig.</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>回归</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.ess)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.essDF)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.essMeanSq)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.fstats)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.sigFStats)}}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>残差</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.rss)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.rssDF)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.rssMeanSq)}}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>总计</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.tss)}}</td>
                                                            <td>{{toFixedNum(value.varianceAnalysis.tssDF)}}</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="area-level2">
                        <h3 class="h3">系数：</h3>
                        <div class="ant-table-wrapper">
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                        <div class="ant-table-content">
                                            <div class="ant-table-body">
                                                <table class="">
                                                    <thead class="ant-table-thead">
                                                        <tr>
                                                            <th rowspan="2" style="width:150px"></th>
                                                            <th colspan="2">未标准化系数</th>
                                                            <th rowspan="2" style="width:150px">标准化系数</th>
                                                            <th rowspan="2" style="width:150px">t</th>
                                                            <th rowspan="2" style="width:150px">Sig.</th>
                                                            <th rowspan="2"></th>
                                                        </tr>
                                                        <tr>
                                                            <th style="width:150px">系数</th>
                                                            <th style="width:150px">标准误差</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="ant-table-tbody">
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>{{value.coefficients.coefficentName[0]}}</td>
                                                            <td>{{toFixedNum(value.coefficients.coefficients[0])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.stdDeviaModelCoe[0])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.standCoeff[0])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.tstats[0])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.sigt[0])}}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr class="ant-table-row ant-table-row-level-0">
                                                            <td>{{value.coefficients.coefficentName[1]}}</td>
                                                            <td>{{toFixedNum(value.coefficients.coefficients[1])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.stdDeviaModelCoe[1])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.standCoeff[1])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.tstats[1])}}</td>
                                                            <td>{{toFixedNum(value.coefficients.sigt[1])}}</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area">
                <divider-title name="模型图" />
                <chart :chartData="lineChart" />
            </div>            

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
import Chart from './components/Chart.vue';
export default {
    components:{DividerTitle,Chart},
    computed:{
        result() {
            let data = getSessionItem('analysis_curvefit');
            return data.analysis_curvefit;
        },
        parts() {
            let arr = []
            if(this.result&&this.result.curvefitBOLst) {
                this.result.curvefitBOLst.forEach((item,key)=>{
                    arr.push({name:item.modelName,key:'nav'+key})
                })                
            }
            arr.push({name:'模型图'})
            return arr;
        },
        curvefitBOLst() {
            if(this.result) {
                return this.result.curvefitBOLst;
            } else {
                return null;
            }
        },
        lineChart() {
            if(this.result) {
                var obj = this.result.lineChart;
                let series = obj.series.map(item=>{return {name:item.name,data:item.data,type:item.type}});
                let legend = {show:true,data:obj.legend.data}
                let xAxis = {
                    data:obj.xAxis[0].data,
                    show:true,
                    type:'category'
                }
                let data = {series,legend,xAxis,yAxis: {type: 'value'},toolbox:obj.toolbox};
                return data;
            } else {
                return null;
            }
        }

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            } else {
                return '-';
            }
        },
    }
}
</script>